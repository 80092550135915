import React, { Component } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faSignOutAlt,
  faHome,
  faDatabase,
  faComment,
  faWallet,
  faUsers,
  faReceipt,
  faClock,
  faIndustry,
  faShoppingCart,
  faBell,
  faFile,
  faServer,
  faTachometerAlt,
  faBuilding,
  faCheck,
  faTicketAlt,
  faMoneyBillWave,
  faCheckCircle,
  faFileWord,
  faChartLine,
  faEnvelope,
  faPager,
  faPassport,
  faReplyAll,
  faHandHoldingUsd
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  PermissionActions,
  BuyerPlatFormActions,
  MerchantActions,
} from "../../../actions";
import { Api } from "../../../services/httpService";
import { url } from "../../../baseURL";
import axios from "axios";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.pathname,
      menuAccessList: {},
    };
  }

  componentDidMount() {
    this.props.getMenuList();
    this.props
      .getUserRoleMenu(window.localStorage.getItem("_role"))
      .then(() => {});
    this.props.getBuyerPlatform();
    this.props.getAllMerchantList();
    // if (this.state.currentUrl && this.state.currentUrl !== '/admin/merchants') {
    //     this.props.getMerchantList();
    // }

    if (window.localStorage.getItem("_role")) {
      let self = this;
      const roleId = window.localStorage.getItem("_role");
      let tokenHeader = "";
      if (localStorage.getItem("_token")) {
        tokenHeader = localStorage.getItem("_token");
      }
      axios({
        method: "get",
        url: url + "/menu/getMenuForRole/" + roleId,
        headers: {
          Authorization: tokenHeader,
        },
      })
        .then(function (response) {
          if (response.status == 200) {
            self.setState({
              menuAccessList: response.data.body,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            setTimeout(() => {
              window.location = "/";
            }, 1000);
          }
        });
    }
  }

  componentDidUpdate() {
    // if (this.state.currentUrl && this.state.currentUrl !== '/admin/merchants') {
    //     this.props.getMerchantList();
    // }
  }

  getFaIcon = (icon) => {
    switch (icon) {
      case "faEye":
        return faEye;
        break;
      case "faHome":
        return faHome;
        break;
      case "faDatabase":
        return faDatabase;
        break;
      case "faComment":
        return faComment;
        break;
      case "faWallet":
        return faWallet;
        break;
      case "faUsers":
        return faUsers;
        break;
      case "faReceipt":
        return faReceipt;
        break;
      case "faClock":
        return faClock;
        break;
      case "faIndustry":
        return faIndustry;
        break;
      case "faShoppingCart":
        return faShoppingCart;
        break;
      case "faBell":
        return faBell;
        break;
      case "faFile":
        return faFile;
      case "faTachometerAlt":
        return faTachometerAlt;
        break;
      case "faServer":
        return faServer;
        break;
      case "faBuilding":
        return faBuilding;
        break;
      case "faCheck":
        return faCheck;
        break;
      case "faTicketAlt":
        return faTicketAlt;
        break;
      case "faMoneyBillWave":
        return faMoneyBillWave;
        break;
      case "faCheckCircle":
        return faCheckCircle;
        break;
      case "faFileWord":
        return faFileWord;
        break;
      case "faChartLine":
        return faChartLine;
        break;
      case "faEnvelope":
        return faEnvelope;
        break;
      case "faPager":
        return faPager;
        break;
        case "faPassport":
            return faPassport;
            break;
            case "faReplyAll":
                return faReplyAll;
                break;
      case "faHandHoldingUsd":
        return faHandHoldingUsd;
        break;

      default:
        break;
    }
  };

  checkRoleMenu = (id) => {
    let roleMenuData = this.props.userRoleMenuList.find(
      (item) => item.menuId == id
    );
    if (roleMenuData) {
      return true;
    } else {
      return false;
    }
  };

  checkMenuChildAccess = (menuId) => {
    let checkStatus = false;
    if (menuId && this.state.menuAccessList?.length > 0) {
      let checkRes = this.state.menuAccessList?.filter(
        (element) => element.id == menuId && element.childHasAccess
      );
      if (checkRes && checkRes.length) {
        checkStatus = true;
      }
      return checkStatus;
    } else {
      return checkStatus;
    }
  };

  checkCurrentRoute = (url) => {
    this.setState({
      currentUrl: url,
    });
  };

  // logout = async () => {
  //     const res = await Api('post', '/users/logout');
  //     if (res) {
  //         localStorage.clear();
  //         window.location = '/';
  //     }
  // }

  render() {
    const { menuList } = this.props;
    return (
      <div className="sidebar">
        {menuList?.map((menu, i) => {
          return (
            <div key={`main-${i}`}>
              {menu.parentId === null &&
              menu.isDisplay &&
              menu?.children?.length > 0 &&
              this.checkMenuChildAccess(menu.id) ? (
                <>
                  {menu.children.length > 0 ? (
                    <h4 className="sidebar-title">{menu.title}</h4>
                  ) : (
                    ""
                  )}
                  {menu.children.length > 0 &&
                    menu.children.map((child, index) => {
                      return (
                        <div key={`child-${index}`}>
                          {this.checkRoleMenu(child.id) ? (
                            <Link
                              key={index}
                              to={child.menuUrl}
                              onClick={() =>
                                this.checkCurrentRoute(child.menuUrl)
                              }
                              className={
                                this.state.currentUrl === child.menuUrl
                                  ? "active_link"
                                  : ""
                              }
                            >
                              <div className="sidebar_item_container">
                                <div className="icon">
                                  <FontAwesomeIcon
                                    icon={this.getFaIcon(child.icon)}
                                  />
                                </div>
                                <p>{child.title}</p>
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                </>
              ) : (
                ""
              )}
            </div>
          );
        })}

        {/* <Link exact to="/admin/requests">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faComment} />
                        </div>
                        <p>Requests</p>
                    </div>
                </Link>
                <Link exact to="/admin/wallet">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faWallet} />
                        </div>
                        <p>Reserve Balance</p>
                    </div>
                </Link>
                <Link exact to="/admin/pending">
                    <div className="sidebar_item_container" >
                        <div className="icon" style={{ top: 16 }} >
                            <FontAwesomeIcon icon={faClock} />
                        </div>
                        <p>Pending</p>
                    </div>
                </Link>
                <h4 className="sidebar-title">Vendor Advance</h4>
                <Link exact to="/admin/purchase">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </div>
                        <p>Purchase Orders</p>
                    </div>
                </Link>
                <Link exact to="/admin/invoices">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Invoices</p>
                    </div>
                </Link>

                <Link exact to="/admin/trade-payments">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faWallet} />
                        </div>
                        <p>Payments</p>
                    </div>
                </Link>
                <h4 className="sidebar-title">Settings</h4>
                <Link to="/admin/buyerplatforms">
                    <div className="sidebar_item_container" >
                        <div className="icon">
                            <FontAwesomeIcon icon={faDatabase} />
                        </div>
                        <p>Buyer Platforms</p>
                    </div>
                </Link>

                <Link exact to="/admin/merchants">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <p>Merchants</p>
                    </div>
                </Link>
                <Link exact to="/admin/investor">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <p>Investors</p>
                    </div>
                </Link>
                <Link exact to="/admin/traditional">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faIndustry} />
                        </div>
                        <p>Factoring Params</p>
                    </div>
                </Link>
                <Link exact to="/admin/client-monitoring">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                        <p>Account Monitoring</p>
                    </div>
                </Link>
                <Link exact to="/admin/document-monitoring">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faFile} />
                        </div>
                        <p>Document Monitoring</p>
                    </div>
                </Link>
                <Link exact to="/admin/notification-template">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                        <p>Notifications</p>
                    </div>
                </Link>
                <Link exact to="/admin/email-template">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                        <p>Email Templates</p>
                    </div>
                </Link>
                <Link exact to="/admin/cms-page">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Cms Pages</p>
                    </div>
                </Link>
                <Link exact to="/admin/permission">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Permission</p>
                    </div>
                </Link> */}
        {/* <Link onClick={() => this.checkCurrentRoute("/admin/cronjobs")} className={this.state.currentUrl === "/admin/cronjobs" ? "active_link" : ""} to="/admin/cronjobs">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faComment} />
                        </div>
                        <p>Cron Jobs</p>
                    </div>
                </Link>
                <Link onClick={() => this.checkCurrentRoute("/admin/remittances")} className={this.state.currentUrl === "/admin/remittances" ? "active_link" : ""} to="/admin/remittances">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faComment} />
                        </div>
                        <p>Remittances</p>
                    </div>
                </Link>
                <Link onClick={() => this.checkCurrentRoute("/admin/configuration")} className={this.state.currentUrl === "/admin/configuration" ? "active_link" : ""} to="/admin/configuration">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faServer} />
                        </div>
                        <p>Configuration</p>
                    </div>
                </Link> */}
        {/* <Link to="" onClick={() => this.logout()}>
                    <div className="sidebar_item_container" >
                        <div className="icon">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </div>
                        <p>Logout</p>
                    </div>
                </Link> */}
        <p className="footer">
          (c) Protected by Law. Sanctioned under Companies Act. Sanctioned under
          Companies Act.
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ PermissionReducers }) => {
  return {
    menuList: PermissionReducers.menuList,
    userRoleMenuList: PermissionReducers.userRoleMenuList,
  };
};

const mapDispatchToProps = {
  getUserRoleMenu: PermissionActions.getUserRoleMenu,
  getMenuList: PermissionActions.getMenuList,
  getMerchantList: MerchantActions.getMerchantList,
  getAllMerchantList: MerchantActions.getAllMerchantList,
  getBuyerPlatform: BuyerPlatFormActions.getBuyerPlatform,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
